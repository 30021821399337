@@ -1,214 +0,0 @@
<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold text-base">Social & Community statistics</span>

      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiFacebook }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Facebook
              </p>
              <span class="text--primary text-xl font-weight-bold">4.1k</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="success"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiInstagram }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Instagram
              </p>
              <span class="text--primary  text-xl font-weight-bold">902</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiTwitter }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Twitter
              </p>
              <span class="text--primary text-xl font-weight-bold">1.2k</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="twitch"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiTwitch }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Twitch
              </p>
              <span class="text--primary text-xl font-weight-bold">3.9k</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiDiscord }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Discord
              </p>
              <span class="text--primary text-xl font-weight-bold">6.2k</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <div class="d-flex">
            <v-avatar
              size="44"
              color="warning"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiAccountGroup }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Faceit Hubs
              </p>
              <span class="text--primary text-xl font-weight-bold">6.3k</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiTwitch,
  mdiDiscord,
  mdiAccountGroup,
} from '@mdi/js'

export default {
  setup() {
    return {
      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiFacebook,
        mdiInstagram,
        mdiTwitter,
        mdiTwitch,
        mdiDiscord,
        mdiAccountGroup,
      },
    }
  },
}
</script>