<template>
  <v-row>
    <v-col
      md="6"
      sm="6"
      cols="12"
    >
      <v-alert
        color="warning"
        dense
        text
        class="mt-6 mb-2 ml-2"
      >
        If you encounter any BUGS / ERRORS please contact DEVS via Slack channel!
      </v-alert>
      <v-card>
        <v-card-title>
          Internal News & Information
        </v-card-title>
        <v-card-text>
          <v-icon
            size="2rem"
            color="primary"
            class="float-left mr-1 mt-1"
          >
            {{ icons.mdiInformation }}
          </v-icon>
          <p>
            BGL Faceit HUB is launching on 14th February! February is Free To Play for BGL players!
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      md="6"
      sm="6"
      cols="12"
    >
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiStarOutline,
  mdiCheckCircleOutline,
  mdiAccountOutline,
  mdiDotsHorizontal,
  mdiTrophy,
  mdiCurrencyEur,
  mdiTwitch,
  mdiCheckboxMarkedCircle,
  mdiInformation,
} from '@mdi/js'

import SocialStatsCard from '@/views/components/about/SocialStatsCard.vue'

export default {
  setup() {
    return {
      SocialStatsCard,
      icons: {
        mdiStarOutline,
        mdiCurrencyEur,
        mdiCheckCircleOutline,
        mdiAccountOutline,
        mdiDotsHorizontal,
        mdiTrophy,
        mdiTwitch,
        mdiCheckboxMarkedCircle,
        mdiInformation,
      },
    }
  },
}
</script>
